import styled from 'styled-components';

export const Button = styled.button`
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
  padding: 1rem 0;
  text-transform: uppercase;
  transition: 0.2s background-color ease;
  color: #fff;
  letter-spacing: 2px;
  text-align: left;

  &:active,
  &:focus {
    outline: none;
  }

  & + & {
    margin-left: 1rem;
  }
  span.icon {
    height: 20px;
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Close = styled.button`
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: 4rem;
  font-weight: normal;
  padding: 1rem;
  line-height: 4rem;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  z-index: 9999;
  cursor: pointer;
  outline: 0;
`;
