import React from 'react';
import styled from 'styled-components';
import Layout from 'components/layout';
import Video from 'components/video';
import MEDIA from 'helpers/mediaTemplates';
import Head from 'components/head';
import bmg from '../images/bmg.png';
import bjorlund from '../images/editionbjorlund.png';
import fujipacific from '../images/fujipacific.png';
import concord from '../images/concord.png';
import mushroom from '../images/mushroomgroup.png';
import riptide from '../images/riptidemg.png';
import fairwood from '../images/fairwood.png';
import downtown from '../images/downtown-publishing.png';
import bluesun from '../images/blue-sun.png';
import d6publishing from '../images/d6-publishing.png';
import oyez from '../images/oyez.png';

export const Container = styled.div`
  max-width: 1200px;
  padding: 500px 30px 0;
  margin: 0 auto;
  ${MEDIA.SMALLDESKTOP`
    padding: 100px 30px 0;
  `};
  .publishers {
    margin-top: 100px;
    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 40px;
      grid-row-gap: 0px;
      align-items: center;
      justify-content: center;
      text-align: center;
      ${MEDIA.SMALLDESKTOP`
    grid-template-columns: repeat(2, 1fr);
  `};
      ${MEDIA.TABLET`
    grid-template-columns: 1fr;
  `};
      li {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        align-items: center;
        justify-content: center;
        height: 200px;
        ${MEDIA.TABLET`
            height:150px;
          `};
        &.text {
          color: #656565;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
        a {
          span {
            text-indent: -9999px;
            display: none;
          }
        }
        svg,
        img {
          width: 40%;
          height: auto;
          margin: 0 auto;
        }
      }
    }
  }
`;

const About = () => (
  <Layout>
    <Head pageTitle="About Us" />
    <Video pagetitle="About Us" />
    <Container>
      <div className="bio">
        <p>
          ASONGS is an independent music publishers based in London, UK. The
          company was started back in 2005 by Founder of Anglo Plugging/Anglo
          Management and Southern Fried Records and is headed by Thomas
          Blackburn &amp; Matt Graux. We have grown into a proactive, forward
          thinking company with a hard working and open minded mentality.
        </p>
        <p>
          We are all musicians and lovers of music. We feel privileged to spend
          our time, discovering, listening to, sharing and supporting Music.
          Asongs strive to be a positive force for Artists, we want to change
          the world with music. Our roster boasts writers and artists from
          varied backgrounds, locations and genres, we are always looking to
          sign new talent. We are particularly active in the sync world having
          secured placements in major TV, film, video game productions,
          advertising campaigns around the world as well as ringtones and
          emerging technologies.
        </p>
      </div>
      <div className="publishers">
        <ul>
          <li className="text">Sub-Publishers</li>
          <li>
            <a
              href="https://www.bmg.com/uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>BMG</span>
              <img src={bmg} alt="BMG Music Publishing" />
            </a>
          </li>
          <li>
            <a
              href="https://www.bjorlund.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Edition Bjorlund</span>
              <img src={bjorlund} alt="Edition Bjorlund" />
            </a>
          </li>
          <li>
            <a
              href="https://www.fairwoodmusic.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Fairwood Music</span>
              <img src={fairwood} alt="Fairwood Music" />
            </a>
          </li>
          <li>
            <a
              href="http://www.fujipacific.co.jp/en/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Fuji Pacific Music INC.</span>
              <img src={fujipacific} alt="Fuji Pacific Music INC." />
            </a>
          </li>
          <li>
            <a
              href="https://concord.com/music-publishing/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Concord Music Publishing</span>
              <img src={concord} alt="Concord Music Publishing" />
            </a>
          </li>
          <li>
            <a
              href="https://mushroomgroup.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Mushroom Group</span>
              <img src={mushroom} alt="Mushroom Group" />
            </a>
          </li>
          <li>
            <a
              href="https://www.riptidemusic.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Riptide Music Group</span>
              <img src={riptide} alt="Riptide Music Group" />
            </a>
          </li>
          <li>
            <a
              href="https://www.dmpgroup.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Downtown Music Publishing</span>
              <img src={downtown} alt="Downtown Music Publishing" />
            </a>
          </li>
          <li>
            <a
              href="https://www.bluesun.one/?lang=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Blue Sun Publishing</span>
              <img src={bluesun} alt="Blue Sun Publishing" />
            </a>
          </li>
          <li>
            <a
              href="https://www.d6publishing.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>D6 Music Publishing</span>
              <img src={d6publishing} alt="D6 Music Publishing" />
            </a>
          </li>
          <li>
            <a
              href="https://oyez.it/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Oyez Publishing</span>
              <img src={oyez} alt="Oyez Publishing" />
            </a>
          </li>
        </ul>
      </div>
    </Container>
  </Layout>
);

export default About;
