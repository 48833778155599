import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { Container } from './video.css';
import Title from 'components/title';
import Modal from 'containers/modal';

const Video = ({ pagetitle }) => {
  const [watchComplete, setWatchComplete] = useState(false);

  const handleWatchComplete = ({ loadedSeconds }) => {
    if (loadedSeconds >= 0.00000001 && !watchComplete) {
      setWatchComplete(true);
    }
  };

  return (
    <Container>
      <div
        className={
          watchComplete
            ? 'overlay-title video-visible'
            : 'overlay-title video-invisible'
        }
      >
        <div className="title-button">
          <Title as="h2">{pagetitle}</Title>
          <Modal>
            <ReactPlayer
              className="modal-react-player"
              url="https://vimeo.com/412804951"
              playing
              width="100%"
              height="100%"
              controls={false}
              loop={true}
            />
          </Modal>
        </div>
      </div>
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/412804951"
          playing
          width="100%"
          height="100%"
          loop={true}
          muted={true}
          onProgress={handleWatchComplete}
        />
      </div>
    </Container>
  );
};

Video.propTypes = {
  pagetitle: PropTypes.string.isRequired,
};

export default Video;
