import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 500px;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  ${MEDIA.SMALLDESKTOP`
              position: relative;
         height: 300px;
         top: auto;
         left: auto;
            `};
  .overlay-title {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 1);
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-items: start;
    align-items: center;
    transition: background-color 0.2s ease-in;
    .title-button {
      max-width: 1200px;
      padding: 0 30px;
      width: 100%;
      margin: 0px auto;
    }
    ${MEDIA.SMALLDESKTOP`
              background-color: rgba(0, 0, 0, 1);
            `};
    &.video-visible {
      background-color: rgba(0, 0, 0, 0.5);
      ${MEDIA.SMALLDESKTOP`
              background-color: rgba(0, 0, 0, 1);
            `};
    }
    h2 {
      display: block;
      font-size: 10rem;
      line-height: 1;
      font-weight: 700;
      ${MEDIA.SMALLDESKTOP`
    font-size: 9rem;
  `};
      ${MEDIA.TABLET`
    font-size: 7rem;
  `};
    }
  }
  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
    margin-top: -10%;

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
      iframe {
        transform: scale(1.5);
      }
    }
  }
`;
